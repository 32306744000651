export default {
  data() {
    return {
      form: {
        accountStartDate: "",
        collectCompanyId: "",
        managementMemberId: "",
        companyFullName: "",
      },
      formQuit: {
        isCBAContract: false,
        contractStatusTypeId: 90,
        isCollectProxy: false,
        collectProxyList: [],
      },
    };
  },
};
