import axios from "axios";
import {API_URL} from "@/const";
import {LEGAL_PERSONALITY_TYPE} from "@/const";

export default {
  data() {
    return {
      collector: {
        isCbaContract: false,
        contractStatusInfo: {
          id: 0,
          name: ""
        },
        accountStartDate: "",
        collectorBaseInfo: {
          businessCode: null,
          legalPersonalityType: {
            id: 0,
            name: ""
          },
          id: 0,
          name: "",
          zipCode: "",
          prefectures: {
            id: 0,
            name: ""
          },
          address: "",
          buildingName: "",
          tel: "",
          fax: "",
          isActive: false
        },
        staffName: "",
        staffMail: "",
        licenceMunicipalityList: [
          {
            licenceLead3No: "",
            municipalityName: "",
            collectLicenceList: [
              {
                collectLicenceId: 0,
                licenceNo: "",
                licenceExpiredDate: "",
                isExcellent: false,
                licenceFileName: "",
                targetJwnetItemList: [
                  {
                    id: 0,
                    name: ""
                  }
                ],
                transshipmentList: [
                  {
                    id: 0,
                    name: "",
                    addressInfo: {
                      zipCode: "",
                      prefecturesId: 0,
                      prefecturesName: "",
                      address: "",
                      buildingName: ""
                    },
                    tel: "",
                    fax: "",
                    jwnetContractNo: "",
                    transshipmentStoreCode: "",
                    area: 0,
                    storageUpperLimit: 0,
                    height: 0,
                    transshipmentJwnetItemList: [
                      {
                        id: 0,
                        name: ""
                      }
                    ],
                    isActive: false
                  }
                ],
                isActive: false,
                isUpdate: false
              }
            ]
          }
        ],
        licenceCityList: [
          {
            cityName: "",
            collectLicenceList: [
              {
                collectLicenceId: 0,
                licenceNo: "",
                licenceExpiredDate: "",
                isExcellent: false,
                licenceFileName: null,
                targetJwnetItemList: [
                  {
                    id: 0,
                    name: ""
                  }
                ],
                isActive: false,
                isUpdate: false
              }
            ]
          }
        ],
        collectFactoryList: [
          {
            id: 0,
            name: "",
            addressInfo: {
              zipCode: "",
              prefecturesId: 0,
              prefecturesName: "",
              address: "",
              buildingName: ""
            },
            tel: "",
            fax: "",
            jwnetContractNo: "",
            ediKey: "",
            publicConfirmationNo: "",
            isActive: false,
            collectOfficeList: [
              {
                id: 0,
                name: "",
                zipCode: "",
                prefecturesId: 0,
                prefecturesName: "",
                address: "",
                buildingName: "",
                tel: "",
                fax: "",
                isActive: false
              }
            ]
          }
        ],
        isCollectProxy: false,
        collectProxyList: [
          {
            id: 0,
            name: ""
          }
        ],
        accountInfo: {
          memberId: 0,
          name: "",
          mail: "",
          tel: "",
          mobilePhone: ""
        }
      },
    };
  },

  methods: {
    // 運搬事業者情報取得API GENERATOR
    getCollectorApi(collectorId) {
      return axios
        .get(`${API_URL.COLLECTOR.DETAIL}${collectorId}`)
        .then((res) => {
          this.collector = res.data;
          this.form.collectCompanyId = res.data.collectorBaseInfo.id;
          this.form.managementMemberId = res.data.accountInfo ? res.data.accountInfo.memberId : null;
          this.form.accountStartDate = res.data.accountStartDate;
          this.form.companyFullName = this.setCompanyFullName(res.data.collectorBaseInfo)
          this.$data.startDate = res.data.accountStartDate;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setCompanyFullName(collectorBaseInfo) {
      var legalPersonalityTypeName = collectorBaseInfo.legalPersonalityType.name;
      var companyName = collectorBaseInfo.name;
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(legalPersonalityTypeName);
      if (matches) {
        var location = matches[1];
        if (location === LEGAL_PERSONALITY_TYPE.LOCATION.BEFORE) {
          return legalPersonalityTypeName.replace(matches[0], "") + companyName ;
        } else if (location === LEGAL_PERSONALITY_TYPE.LOCATION.AFTER) {
          return companyName + legalPersonalityTypeName.replace(matches[0], "");
        }
        return companyName;
      } else return companyName;
    },
  },
};
