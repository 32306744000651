<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="運搬事業者詳細" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />
      <div class="l-container">
        <div class="c-alart">
          <div class="icon"></div>
          <p>契約データの一括登録中に、関連事業者の情報を編集しないでください。実施前後には必ず社内への周知を行ってください。</p>
        </div>
      </div>
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <!--企業情報-->
        <div class="l-contents l-box">
          <div class="l-box title-wrap l-flex">
            <h3 class="c-lead icon">企業情報</h3>
            <div v-if ="!isCollector" class="c-collectorText">

              <div class="c-collectorContent">準備完了メール送信日</div>
              <InputDate
                v-show="!form.accountStartDate"
                :value.sync="startDate"
                :validation="validateBlank"
                :isValid.sync="validate.sendMail.accountStartDate.isValid"
                :errMsg.sync="validate.sendMail.accountStartDate.errMsg"
                class="c-collectorContent"
              />
              <div v-show="form.accountStartDate" class="c-collectorContent">
                {{ collector.accountStartDate | dateJpYMDFormat }}
              </div>
              <div v-if="!form.accountStartDate"
                :class="{ disabled: !collector.isCbaContract || collector.contractStatusInfo.id !== 10 || isProcessing }"
                @click="formHandler()"
                class="c-btn secondary sm c-collectorContent"
              >
                準備完了メール送付
              </div>
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>企業名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.companyFullName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                〒{{ collector.collectorBaseInfo.zipCode }}
                {{ collector.collectorBaseInfo.prefectures.name }}
                {{ collector.collectorBaseInfo.address }}
                {{ collector.collectorBaseInfo.buildingName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ collector.collectorBaseInfo.tel }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ collector.collectorBaseInfo.fax | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ collector.staffName }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者メールアドレス</label>
              </div>
              <div class="c-inputWrap__items word-break">
                {{ collector.staffMail }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者コード</label>
              </div>
              <div class="c-inputWrap__items word-break">
                <dd>{{ collector.collectorBaseInfo.businessCode | blankText }}</dd>
              </div>
            </div>
          </div>
        </div>
        <!--企業情報ここまで-->
        <!--産業廃棄物許可証情報-->
        <div
          v-for="(municipality, municipalityIndex) in collector.licenceMunicipalityList"
          :key="'m-' + municipalityIndex"
        >
          <div class="c-lead-s-black">
            {{ municipality.municipalityName }}
          </div>
          <div
            v-for="(licence, licenceIndex) in municipality.collectLicenceList"
            :key="'m-' + municipalityIndex + '-licence-' + licenceIndex"
          >
            <div class="l-contents l-box">
              <div class="l-box title-wrap">
                <h3 class="c-lead icon">許可証情報</h3>
              </div>
              <div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>ステータス</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div v-if="!licence.isActive">無効</div>
                    <div v-else-if="licence.isUpdate">更新中</div>
                    <div v-else>有効</div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可証番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ licence.licenceNo }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可証期限</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ licence.licenceExpiredDate | dateJpYMDFormat }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>優良認定</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div v-if="licence.isExcellent" class="c-excellent"/>
                    <div v-else>-</div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可証PDF</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <a v-if="licence.licenceFileName" class="c-text text-link c-entrustText__text"
                       @click="downloadLicenceFile(licence.collectLicenceId, licence.licenceFileName, false)">
                      {{ licence.licenceFileName }}
                    </a>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>対象廃棄物種類</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ licence.targetJwnetItemList.map(jwnetItem => jwnetItem.name).join("、") }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>積替保管情報</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div
                      class="c-accordion"
                      v-for="transship in licence.transshipmentList"
                      :key="'transshipment-' + transship.id"
                    >
                      <input
                        :id="'factoryList-acc-' + transship.id"
                        class="c-accordion__cb"
                        type="checkbox"
                      />
                      <div class="c-accordion__head">
                        <label
                          class="c-accordion__head__lbl"
                          :for="'factoryList-acc-' + transship.id"
                        ></label>
                        <div class="c-accordion__head__text">
                          {{ transship.name }}
                        </div>
                      </div>
                      <div class="c-accordion__body">
                        <div class="c-infoText">
                          <div class="c-dl-wide">
                            <dt class="sm">住所</dt>
                            <dd>
                              〒{{ transship.addressInfo.zipCode }}
                              {{ transship.addressInfo.prefecturesName }}
                              {{ transship.addressInfo.address }}
                              {{ transship.addressInfo.buildingName }}
                            </dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">電話番号</dt>
                            <dd>{{ transship.tel | blankText }}</dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">FAX番号</dt>
                            <dd>{{ transship.fax | blankText }}</dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">JWNETID(加入者番号)</dt>
                            <dd>{{ transship.jwnetContractNo | blankText }}</dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">事業所コード(積保)</dt>
                            <dd>
                              {{ transship.transshipmentStoreCode | blankText }}
                            </dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">面積</dt>
                            <dd>{{ transship.area | formatFloat }} m&sup2;</dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">保管上限</dt>
                            <dd>{{ transship.storageUpperLimit | formatFloat }} m&sup3;</dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">積み上げ高さ</dt>
                            <dd>{{ transship.height | formatFloat }} m</dd>
                          </div>
                          <div class="c-dl-wide">
                            <dt class="sm">積替できる廃棄物種類</dt>
                            <dd>{{ transship.transshipmentJwnetItemList.map(t => t.name).join("、") }}</dd>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="!licence.transshipmentList.length">-</template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--許可証情報ここまで-->

        <!--一般廃棄物許可証情報-->
        <div
          v-for="(licenceCity, licenceCityIndex) in collector.licenceCityList"
          :key="'lc-' + licenceCityIndex"
        >
          <div class="c-lead-s-black">
            {{ licenceCity.cityName }}
          </div>
          <div
            v-for="(licence, licenceIndex) in licenceCity.collectLicenceList"
            :key="'lc-' + licenceCityIndex + '-licence-' + licenceIndex"
          >
            <div class="l-contents l-box">
              <div class="l-box title-wrap">
                <h3 class="c-lead icon">許可証情報</h3>
              </div>
              <div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>ステータス</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div v-if="!licence.isActive">無効</div>
                    <div v-else-if="licence.isUpdate">更新中</div>
                    <div v-else>有効</div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可証番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ licence.licenceNo }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可証期限</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ licence.licenceExpiredDate | dateJpYMDFormat }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>優良認定</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div v-if="licence.isExcellent" class="c-excellent"/>
                    <div v-else>-</div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可証PDF</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <a v-if="licence.licenceFileName" class="c-text text-link c-entrustText__text"
                       @click="downloadLicenceFile(licence.collectLicenceId, licence.licenceFileName, true)">
                      {{ licence.licenceFileName }}
                    </a>
                    <span v-else>-</span>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>対象廃棄物種類</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ licence.targetJwnetItemList.map(jwnetItem => jwnetItem.name).join("、") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--一般許可証情報ここまで-->

        <!--事業所情報-->
        <div v-for="factory in collector.collectFactoryList" :key="'factory-' + factory.id">
          <div class="l-contents l-box">
            <div class="l-box title-wrap">
              <h3 class="c-lead icon">事業所情報</h3>
            </div>
            <div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>事業所名</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ factory.name }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>住所</label>
                </div>
                <div class="c-inputWrap__items">
                  〒{{ factory.addressInfo.zipCode }}
                  {{ factory.addressInfo.prefecturesName }}
                  {{ factory.addressInfo.address }}
                  {{ factory.addressInfo.buildingName }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>電話番号</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ factory.tel | blankText }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>FAX番号</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ factory.fax | blankText }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>JWNETID(加入者番号)</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ factory.jwnetContractNo | blankText }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>EDI利用可能キー</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ factory.ediKey | blankText }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>公開確認番号</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ factory.publicConfirmationNo | blankText }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>営業所情報</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    class="c-accordion"
                    v-for="office in factory.collectOfficeList"
                    :key="'officeList-' + office.id"
                  >
                    <input
                      :id="'officeList-acc-' + office.id"
                      class="c-accordion__cb"
                      type="checkbox"
                    />
                    <div class="c-accordion__head">
                      <label
                        class="c-accordion__head__lbl"
                        :for="'officeList-acc-' + office.id"
                      ></label>
                      <div class="c-accordion__head__text">
                        {{ office.name }}
                      </div>
                    </div>
                    <div class="c-accordion__body">
                      <div class="c-infoText">
                        <div class="c-dl-wide">
                          <dt class="sm">住所</dt>
                          <dd>
                            〒{{ office.zipCode }}
                            {{ office.prefecturesName }}
                            {{ office.address }}
                            {{ office.buildingName }}
                          </dd>
                        </div>
                        <div class="c-dl-wide">
                          <dt class="sm">電話番号</dt>
                          <dd>{{ office.tel | blankText }}</dd>
                        </div>
                        <div class="c-dl-wide">
                          <dt class="sm">FAX番号</dt>
                          <dd>{{ office.fax | blankText }}</dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--事業所情報ここまで-->

        <template v-if="collector.isCbaContract">
          <!--契約情報-->
          <div class="l-contents l-box">
            <div class="l-box title-wrap">
              <h3 class="c-lead icon">契約情報</h3>
            </div>
            <div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>代理登録</label>
                </div>
                <div>
                  <div class="c-inputWrap__items">{{collector.isCollectProxy ? '有' : '無'}}</div>
                  <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols onTop l-area">
                    <div>
                      <div>
                        <dl class="c-dl l-align" v-if="collector.collectReferenceGenerateCompanyList">
                          <dt class="w24">マニフェスト閲覧対象排出事業者：</dt>
                          <dd>
                            {{ joinComma(collector.collectReferenceGenerateCompanyList) }}
                          </dd>
                        </dl>
                        <dl class="c-dl l-align" v-if="collector.collectProxyList && collector.isCollectProxy">
                          <dt class="w24">代理予約排出事業者：</dt>
                          <dd>
                            {{ joinComma(collector.collectProxyList) }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--契約情報ここまで-->

          <!--管理者アカウント情報-->
          <div class="l-contents l-box">
            <div class="l-box title-wrap">
              <h3 class="c-lead icon">管理者アカウント情報</h3>
            </div>
            <div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>氏名</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ collector.accountInfo.name }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>メールアドレス</label>
                </div>
                <div class="c-inputWrap__items word-break">
                  {{ collector.accountInfo.mail }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>電話番号</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ collector.accountInfo.tel }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>電話番号(携帯)</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ collector.accountInfo.mobilePhone }}
                </div>
              </div>
            </div>
          </div>
          <!--管理者アカウント情報ここまで-->
        </template>
      </div>

      <FloatButtons>
        <div class="left">
          <router-link to="/operate" class="c-btn secondary small back">一覧へ戻る</router-link>
        </div>
        <div class="center">
          <template v-if="isCollector">
            <div class="c-btn secondary small" style="visibility: hidden"></div>
          </template>
          <template v-else>
            <div class="c-btn secondary small" @click="isDisplayConfirmModal = true">{{ collector.collectorBaseInfo.isActive ? "無効化" : "有効化" }}</div>
            <router-link :to="`/collector/step1/edit/${$route.params.id}`" class="c-btn secondary small">編集</router-link>
            <template v-if="collector.isCbaContract">
              <router-link :to="`/collector/cbacontract/edit/${$route.params.id}`" class="c-btn secondary small">CBA加入情報編集</router-link>
              <div class="c-btn secondary small" @click="isDisplayQuitModal = true">CBA契約終了</div>
            </template>
            <template v-else>
              <router-link :to="`/collector/cbacontract/input/${$route.params.id}`"
                           class="c-btn secondary small">
                CBA加入登録
              </router-link>
            </template>
          </template>
        </div>
        <div class="right">
          <router-link to="/account" class="c-btn secondary small next">アカウント一覧へ</router-link>
        </div>
      </FloatButtons>
    </main>

    <!--準備完了メール送信確認モーダル-->
    <div class="c-modal" v-if="isDisplayMailModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          準備完了メールを送信します。<br />よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn secondary small"
              @click="isDisplayMailModal = false"
          >
            キャンセル
          </div>
          <div :class="{ isProcessing }" class="c-btn primary small" @click="confirmHandler()">
            送信
          </div>
        </div>
      </div>
    </div>
    <!--準備完了メール送信確認モーダルここまで-->

    <!--契約有効化/無効化モーダル-->
    <div class="c-modal" v-if="isDisplayConfirmModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          運搬事業者を{{ collector.collectorBaseInfo.isActive ? "無効化" : "有効化" }}します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayConfirmModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="contractHandler()">
            OK
          </div>
        </div>
      </div>
    </div>
    <!--契約有効化/無効化モーダルここまで-->

    <!--CBA契約終了確認モーダル-->
    <div class="c-modal" v-if="isDisplayQuitModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          CBA契約を終了します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn secondary small"
              @click="isDisplayQuitModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="quitHandler()">OK</div>
        </div>
      </div>
    </div>
    <!--CBA契約終了確認モーダルここまで-->

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputDate from "@/components/parts/input/InputDate";
import validation from "@/mixin/validation";
import callApi from "./mixins/callApi";
import formValue from "./mixins/formValue";
import formValidation from "./mixins/formValidation";
import { API_URL } from "@/const";
import { saveAs } from "file-saver";
import ToastMessage from "@/components/parts/ToastMessage";
import FloatButtons from "@/components/layout/FloatButtons";

export default {
  name: "collector",
  components: {
    FloatButtons,
    ToastMessage,
    InputDate,
  },
  mixins: [callApi, formValue, validation, formValidation],
  data() {
    return {
      collectorId: null,
      startDate: "",
      errMsgs: [],
      toastMsg: "",
      isDisplayMailModal: false,
      isDisplayConfirmModal: false,
      isDisplayQuitModal: false,
      isProcessing: false,
    };
  },
  computed: {
    isCba() {
      return this.$store.getters["userInfo/isCba"];
    },
    isCollector() {
      return this.$store.getters["userInfo/isCollector"];
    },
  },
  methods: {
    // オブジェクトの配列を"、"区切りの文字列に変換する
    // [{"id":0, "name": "name1"}, {"id": 1, "name": "name2"}] -> name1、name2
    joinComma(arr) {
      let tempArray = [];
      for (const i in arr) {
        tempArray.push(arr[i].name);
      }
      return tempArray.join("、");
    },

    //許可証のファイルをダウンロード
    downloadLicenceFile(collectLicenceId, licenceFileName, isCommonLicence) {
      const params = new URLSearchParams();
      params.append("isCommonLicence", isCommonLicence)
      axios
        .get(API_URL.COLLECTOR.LICENCE_DOWNLOAD + collectLicenceId + '?' + params.toString(), {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, licenceFileName);
        });
    },
    confirmHandler() {
      this.isProcessing = true;
      this.$data.isDisplayMailModal = false;
      const form = {
        collectCompanyId: this.form.collectCompanyId,
        accountStartDate: this.startDate,
        managementMemberId: this.form.managementMemberId,
      };
      axios
        .put(API_URL.COLLECTOR.SENDMAIL, form)
        .then((res) => {
          this.isProcessing = false;
          // リロード
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
          console.log(res);
        })
        .catch((err) => {
          this.isProcessing = false;
          this.errMsgs.push(err.message);
        });
    },
    // 準備完了メール送信押下時
    formHandler() {
      if (!this.runValidate()) return;
      this.$data.isDisplayMailModal = true;
    },
    // 契約有効化/無効化モーダル OK押下
    contractHandler() {
      this.$data.isDisplayConfirmModal = false;
      const isActive = !this.collector.collectorBaseInfo.isActive;
      axios
        .patch(API_URL.COLLECTOR.DETAIL + this.$route.params.id, {
          isActive,
        })
        .then(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.getCollectorApi(this.collectorId);
          this.toastMsg = `運搬事業者を${ isActive ? "有効化" : "無効化"}しました。`;
        })
        .catch((err) => {
          this.errMsgs.push(err.message);
        });
    },
    // CBA契約終了確認モーダル OK押下
    quitHandler() {
      this.$data.isDisplayQuitModal = false;
      axios
        .put(API_URL.COLLECTOR.CBACONTRACT_EDIT + this.$route.params.id, this.formQuit)
        .then((res) => {
          // リロード
          this.$router.go({
            path: this.$router.currentRoute.path,
            force: true,
          });
          console.log(res);
        })
        .catch((err) => {
          this.errMsgs.push(err.message);
        });
    },
  },
  mounted() {
    this.collectorId = +this.$route.params.id;
    this.getCollectorApi(this.collectorId);
  },
};
</script>
<style scoped>
.w24 {
  width: 24rem;
}
.l-align {
      align-items: flex-start;
}
</style>
